<template>
  <b-overlay
    :show="show"
    rounded="sm"
    variant="primary"
    :opacity="0.3"
    blur="2px"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              Hoşgeldiniz! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Sisteme Giriş Yapabilmek İçin E-Posta Adresiniz ve Şifrenizi
              Giriniz..
            </b-card-text>

            <!-- form -->
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="E-Posta Adresi">
                <b-form-input v-model="emailAddress" placeholder="" />
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Parola</label>
                  <!-- <b-link :to="{ name: 'parolamı-unuttum' }">
                    <small>Parolamı Unuttum</small>
                  </b-link> -->
                </div>

                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Beni Unutma
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Giriş
              </b-button>
            </b-form>

            <b-card-text class="text-center mt-2"> </b-card-text>

            <!-- divider -->
            <div class="divider my-2"></div>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BOverlay,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import axios from "axios";
import signalRService from "../signalR";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BOverlay,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    axios,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      show: false,
      password: "",
      emailAddress: "",
      sideImg: require("@/assets/images/pages/undraw_shopping_app_flsj.svg"),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/undraw_shopping_app_flsj.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.show = true;
      useJwt
        .login({
          emailAddress: this.emailAddress,
          password: this.password,
        })
        .then(async (response) => {
          if (response.status == 200) {
            var userData = response.data;
            useJwt.setToken(userData.accessToken);
            userData = userData.userItem;
            userData.role = userData.role.caption;

            await this.$http
              .get("menus", {
                headers: {
                  Authorization: "Bearer " + response.data.accessToken,
                },
              })
              .then((response) => {
                userData.ability = [];
                userData.ability.push({
                  action: "hata",
                  subject: "error-pages",
                });
                userData.ability.push({
                  action: "read",
                  subject: "home",
                });
                userData.ability.push({
                  action: "read",
                  subject: "settings",
                });
                var userMenu = response.data;
                for (var x = 0; x < userMenu.length; x++) {
                  var satirlar = userMenu[x].pages;
                  for (var y = 0; y < satirlar.length; y++) {
                    var sayfa = satirlar[y];
                    userData.ability.push({
                      action: "read",
                      subject: sayfa.pageKey,
                    });
                  }
                }

                localStorage.setItem("RPUserData", JSON.stringify(userData));
                signalRService.initializeConnection();
              })
              .catch((err) => {
                console.log(err);
              });

            this.$ability.update(userData.ability);
            this.show = false;
            var backUrl = localStorage.getItem("backPage");
            if (backUrl !== undefined && backUrl !== null && backUrl !== "") {
              localStorage.removeItem("backPage");
              window.location.href = backUrl;
            } else {
              this.$router.replace("home").then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Merhaba ${userData.fullName}`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Giriş Başarılı. Sayın: ${userData.role}!`,
                  },
                });
              });
            }
          }
        })
        .catch((error) => {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
          //console.log(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
